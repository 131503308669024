import { browser } from '$app/environment';
import {
    PUBLIC_SUPABASE_URL,
    PUBLIC_SUPABASE_ANON_KEY,
  } from "$env/static/public";


const supabaseUrl = PUBLIC_SUPABASE_URL as string;
const supabaseAnonKey = PUBLIC_SUPABASE_ANON_KEY as string;


const headers = {
  'apikey': supabaseAnonKey,
  'Content-Type': 'application/json',
  'Prefer': 'return=representation'
};

async function fetchFromSupabase<T>(
  endpoint: string, 
  options: RequestInit & { queryParams?: Record<string, string> } = {}
): Promise<{ data: T | null; error: any }> {

  let url = `${supabaseUrl}/${endpoint}`;
  
  if (options.queryParams) {
    const queryString = new URLSearchParams(options.queryParams).toString();
    url = `${url}?${queryString}`;
  }
  
  try {
    const response = await fetch(url, {
      method: options.method || 'GET',
      headers: { ...headers, ...options.headers },
      body: options.body
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      return { data: null, error: errorData };
    }
    
    const data = await response.json();
    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getById<T>(table: string, id: string | number): Promise<{ data: T | null; error: any }> {
  return fetchFromSupabase<T>(`rest/v1/${table}`, {
    queryParams: { 'id': `eq.${id}` }
  });
}

export async function query<T>(
  table: string, 
  params: Record<string, any> = {}
): Promise<{ data: T[] | null; error: any }> {
  // Convert the params object to Supabase query format
  const queryParams: Record<string, string> = {};
  
  Object.entries(params).forEach(([key, value]) => {
    if (key === 'select') {
      queryParams['select'] = value as string;
    } else if (key === 'order') {
      const [column, direction] = (value as string).split('.');
      queryParams['order'] = `${column}.${direction || 'desc'}`;
    } else if (key === 'limit') {
      queryParams['limit'] = value as string;
    } else if (key === 'offset') {
      queryParams['offset'] = value as string;
    } else if (typeof value === 'string' && value.includes('.')) {
      // Handle operators like eq, gt, lt, etc.
      queryParams[key] = value as string;
    } else {
      // Default to equality
      queryParams[key] = `eq.${value}`;
    }
  });
  
  return fetchFromSupabase<T[]>(`rest/v1/${table}`, { queryParams });
}

// Insert a record - not tested for now
export async function insert<T>(
  table: string, 
  data: Record<string, any>
): Promise<{ data: T | null; error: any }> {
  return fetchFromSupabase<T>(`rest/v1/${table}`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

// Update a record - not tested for now
export async function update<T>(
  table: string, 
  id: string | number, 
  data: Record<string, any>
): Promise<{ data: T | null; error: any }> {
  return fetchFromSupabase<T>(`rest/v1/${table}`, {
    method: 'PATCH',
    queryParams: { 'id': `eq.${id}` },
    body: JSON.stringify(data)
  });
}

// Delete a record - not tested for now
export async function remove<T>(
  table: string, 
  id: string | number
): Promise<{ data: T | null; error: any }> {
  return fetchFromSupabase<T>(`rest/v1/${table}`, {
    method: 'DELETE',
    queryParams: { 'id': `eq.${id}` }
  });
}

export async function getContentByParentId<T>(
  parentId: string | number,
  projectKey: string,
  isPublished: boolean = true,
  options: {
    limit?: number;
    offset?: number;
    orderBy?: string;
    ascending?: boolean;
  } = {}
): Promise<{ data: T[] | null; error: any }> {
  const params: Record<string, any> = {
    'parent_id': `eq.${parentId}`,
    'project_key': `eq.${projectKey}`,
    'is_published': `eq.${isPublished}`
  };
  
  // Handle ordering
  if (options.orderBy) {
    params['order'] = `${options.orderBy}.${options.ascending ? 'asc' : 'desc'}`;
  } else {
    params['order'] = 'created_at.desc';
  }
  
  // Handle pagination
  if (options.limit) {
    params['limit'] = options.limit.toString();
  }
  
  if (options.offset) {
    params['offset'] = options.offset.toString();
  }
  
  return query<T>('content', params);
}

export default {
  query,
  getById,
  insert,
  update,
  remove,
  getContentByParentId: getContentByParentId
};